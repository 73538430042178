import { i18n } from '@/commons/config/i18n';
import { ApiErrorRecord } from '@/commons/models';

export default class ApiError {
  errorCode: number;
  message: string;
  translationKey: string;

  constructor(errorRecord?: ApiErrorRecord) {
    const defaultErrorRecord = { status: 0, data: null, translationKey: null };
    const { status, data, translationKey } = errorRecord ?? defaultErrorRecord;

    this.errorCode = status;
    this.message = data?.content?.exceptionMessage ?? '';
    this.printLog();
    this.translationKey = translationKey ?? 'error.generic';
  }

  getMessage() {
    if (!i18n.te(this.translationKey)) return this.message;

    return <string>i18n.t(this.translationKey);
  }

  private printLog() {
    if (this.message) {
      console.error(this.message);
    }
  }
}